jQuery.fn.extend(
  (function() {
    var clickAttr = function(_caller, attr, fnObj) {
      this.find("[" + attr + "]").click(function() {
        var attrName = this.getAttribute(attr);
        if (attrName in fnObj) {
          fnObj[attrName].call(_caller, this, attr);
        }
      });
      return this;
    };

    var bindFormControl = function() {};

    return {
      clickAttr: clickAttr
    };
  })()
);
