axboot.promise = function() {
  var myClass = function() {
    this.busy = false;
    this._catch = function() {};
    this.queue = [];
    this.then = function(fn) {
      this.queue.push(fn);
      this.exec({});
      return this;
    };
    this.exec = function(data) {
      if (this.busy) return this;
      var Q = this.queue.shift(),
        self = this;

      if (Q) {
        self.busy = true;

        try {
          Q(
            function(a) {
              self.busy = false;
              self.exec(a);
            },
            function(e) {
              self._catch(e);
            },
            data || {}
          );
        } catch (e) {
          self._catch(e);
        }
      } else {
        self.busy = false;
      }
    };
    this.catch = function(fn) {
      this._catch = fn;
      return this;
    };
  };

  return new myClass();
};
