/**
 * axboot 오브젝트 axboot 애플리케이션을 편리하게 사용하기 위한 오브젝트 입니다.
 * @var {Object} axboot
 */
var axboot = {};

/**
 * axboot의 환경 변수 저장 공간
 * @type {Object} axboot.def
 * @example
 * ```js
 * axboot.def.menuHeight = 20;
 * // 와 같이 원하는 속성을 저장 / 사용 할 수 있다.
 * ```
 */
axboot.def = {
  pageFunctionName: "fnObj",
  iframeLoadingMsg: '<i class="cqc-chequer ax-loading-icon lg"></i>'
};

/**
 * document ready 상태가 되었을 때 실행됩니다. 애플리케이션 초기화를 담당합니다.
 * @method axboot.init
 */
axboot.init = function() {
  setTimeout(function() {
    if (typeof parent.COMMON_CODE === "undefined" && window.SCRIPT_SESSION) {
      // API : /api/v1/commonCodes/getAllByMap
      axboot.ajax({
        url: "/api/v1/commonCodes/getAllByMap",
        callback: function(res) {
          parent.COMMON_CODE = axboot.convertCode(res);
          axboot.pageStart();
        },
        options: { nomask: true }
      });
    } else {
      parent.COMMON_CODE = axboot.convertCode(parent.COMMON_CODE);
      axboot.pageStart();
    }

    $(document.body).on("mousedown", "input", function() {
      this.focus();
    });
  });
};

/**
 * axboot.def.pageFunctionName의 pageStart를 실행해 줍니다.
 * @method axboot.pageStart
 *
 */
axboot.pageStart = function() {
  if (window.fcObj && fcObj.pageStart) {
    fcObj.pageStart();
  }
  if (
    window[axboot.def.pageFunctionName] &&
    window[axboot.def.pageFunctionName].pageStart
  ) {
    // 프레임 셋에 타이머 초기화.
    if (top.fnObj && top.fnObj.activityTimerView) {
      top.fnObj.activityTimerView.update();
    }
    window[axboot.def.pageFunctionName].pageStart();
  }
};
/**
 * axboot.def.pageFunctionName의 pageResize를 실행해 줍니다.
 * @method axboot.pageResize
 */
axboot.pageResize = function() {
  if (window.fcObj && fcObj.pageResize) {
    fcObj.pageResize();
  }
  if (
    window[axboot.def.pageFunctionName] &&
    window[axboot.def.pageFunctionName].pageResize
  ) {
    window[axboot.def.pageFunctionName].pageResize();
  }
};

///// ~~~~~~~~~~~~~~~~~~~~~~
$(document.body).ready(function() {
  axboot.preparePlugin.pageStart();
  axboot.init();

  document.createElement("lang");
});

window.onError = function() {
  window.CollectGarbage && window.CollectGarbage();
};

window.onUnload = function() {
  window.CollectGarbage && window.CollectGarbage();
};
