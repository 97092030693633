// forked from https://github.com/chriso/validator.js

axboot.validator = (function() {
  function assertString(input) {
    var isString = typeof input === "string" || input instanceof String;

    if (!isString) {
      throw new TypeError("This library (validator.js) validates strings only");
    }
  }

  function toString(input) {
    if (
      (typeof input === "undefined" ? "undefined" : _typeof(input)) ===
        "object" &&
      input !== null
    ) {
      if (typeof input.toString === "function") {
        input = input.toString();
      } else {
        input = "[object Object]";
      }
    } else if (
      input === null ||
      typeof input === "undefined" ||
      (isNaN(input) && !input.length)
    ) {
      input = "";
    }
    return String(input);
  }

  function contains(str, elem) {
    assertString(str);
    return str.indexOf(toString(elem)) >= 0;
  }

  function matches(str, pattern, modifiers) {
    assertString(str);
    if (Object.prototype.toString.call(pattern) !== "[object RegExp]") {
      pattern = new RegExp(pattern, modifiers);
    }
    return pattern.test(str);
  }

  function merge() {
    var obj =
      arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var defaults = arguments[1];

    for (var key in defaults) {
      if (typeof obj[key] === "undefined") {
        obj[key] = defaults[key];
      }
    }
    return obj;
  }

  /* eslint-disable prefer-rest-params */
  function isByteLength(str, options) {
    assertString(str);
    var min = void 0;
    var max = void 0;
    if (
      (typeof options === "undefined" ? "undefined" : _typeof(options)) ===
      "object"
    ) {
      min = options.min || 0;
      max = options.max;
    } else {
      // backwards compatibility: isByteLength(str, min [, max])
      min = arguments[1];
      max = arguments[2];
    }
    var len = encodeURI(str).split(/%..|./).length - 1;
    return len >= min && (typeof max === "undefined" || len <= max);
  }

  var default_fqdn_options = {
    require_tld: true,
    allow_underscores: false,
    allow_trailing_dot: false
  };

  function isFQDN(str, options) {
    assertString(str);
    options = merge(options, default_fqdn_options);

    /* Remove the optional trailing dot before checking validity */
    if (options.allow_trailing_dot && str[str.length - 1] === ".") {
      str = str.substring(0, str.length - 1);
    }
    var parts = str.split(".");
    if (options.require_tld) {
      var tld = parts.pop();
      if (
        !parts.length ||
        !/^([a-z\u00a1-\uffff]{2,}|xn[a-z0-9-]{2,})$/i.test(tld)
      ) {
        return false;
      }
      // disallow spaces
      if (/[\s\u2002-\u200B\u202F\u205F\u3000\uFEFF\uDB40\uDC20]/.test(tld)) {
        return false;
      }
    }
    for (var part, i = 0; i < parts.length; i++) {
      part = parts[i];
      if (options.allow_underscores) {
        part = part.replace(/_/g, "");
      }
      if (!/^[a-z\u00a1-\uffff0-9-]+$/i.test(part)) {
        return false;
      }
      // disallow full-width chars
      if (/[\uff01-\uff5e]/.test(part)) {
        return false;
      }
      if (part[0] === "-" || part[part.length - 1] === "-") {
        return false;
      }
    }
    return true;
  }

  var default_email_options = {
    allow_display_name: false,
    require_display_name: false,
    allow_utf8_local_part: true,
    require_tld: true
  };

  /* eslint-disable max-len */
  /* eslint-disable no-control-regex */
  var displayName = /^[a-z\d!#\$%&'\*\+\-\/=\?\^_`{\|}~\.\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+[a-z\d!#\$%&'\*\+\-\/=\?\^_`{\|}~\,\.\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF\s]*<(.+)>$/i;
  var emailUserPart = /^[a-z\d!#\$%&'\*\+\-\/=\?\^_`{\|}~]+$/i;
  var quotedEmailUser = /^([\s\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e]|(\\[\x01-\x09\x0b\x0c\x0d-\x7f]))*$/i;
  var emailUserUtf8Part = /^[a-z\d!#\$%&'\*\+\-\/=\?\^_`{\|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+$/i;
  var quotedEmailUserUtf8 = /^([\s\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|(\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*$/i;
  /* eslint-enable max-len */

  /* eslint-enable no-control-regex */

  function isEmail(str, options) {
    assertString(str);
    options = merge(options, default_email_options);

    if (options.require_display_name || options.allow_display_name) {
      var display_email = str.match(displayName);
      if (display_email) {
        str = display_email[1];
      } else if (options.require_display_name) {
        return false;
      }
    }

    var parts = str.split("@");
    var domain = parts.pop();
    var user = parts.join("@");

    var lower_domain = domain.toLowerCase();
    if (lower_domain === "gmail.com" || lower_domain === "googlemail.com") {
      user = user.replace(/\./g, "").toLowerCase();
    }

    if (
      !isByteLength(user, { max: 64 }) ||
      !isByteLength(domain, { max: 254 })
    ) {
      return false;
    }

    if (!isFQDN(domain, { require_tld: options.require_tld })) {
      return false;
    }

    if (user[0] === '"') {
      user = user.slice(1, user.length - 1);
      return options.allow_utf8_local_part
        ? quotedEmailUserUtf8.test(user)
        : quotedEmailUser.test(user);
    }

    var pattern = options.allow_utf8_local_part
      ? emailUserUtf8Part
      : emailUserPart;

    var user_parts = user.split(".");
    for (var i = 0; i < user_parts.length; i++) {
      if (!pattern.test(user_parts[i])) {
        return false;
      }
    }

    return true;
  }

  return {
    isEmail: isEmail
  };
})();
